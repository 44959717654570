.fullPageOverlay {
    position: absolute;
    z-index: 10;
    background: linear-gradient(261.35deg, #343029 0%, #242424 96.26%);
    opacity: 0;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 0;

    &.show {
        opacity: 0.96;
        pointer-events: auto;
    }

    .faq {
        max-width: 800px;
        .question {
            font-weight: bold;
            color: #fff;
            font-size: 20px;
            margin: 20px 0;
        }
        .line {
            margin: 10px 0;
        }
    }

    .error-text {
        color: #b7ff6e;
        margin-bottom: 20px;
        padding: 0 10%;
        text-align: center;
        word-break: break-all;

        a {
            color: #b7ff6e;
        }
    }

    .button {
        background: #b7ff6e;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        font-weight: bold;
        font-size: 12px;
        color: #201f1d;
        cursor: pointer;
        padding: 8px 25px;
        transition: all 0.4s ease;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.topbar {
    background-color: #161518;
    height: 85px;
    width: 100vw;
    display: flex;
    z-index: 3;
    position: relative;

    .left {
        font-family: Consolas, serif;
        font-weight: bold;
        font-size: 18px;
        color: #ffc06e;
        padding-left: 40px;
        display: flex;
        align-items: center;
    }
    .right {
        margin-left: auto;
        display: flex;
        align-items: center;
        padding-right: 40px;

        .menu {
            display: flex;
            align-items: center;
            color: #fff;

            .actual-items {
                display: flex;
                align-items: center;
                transition: all 0.4s ease;
                opacity: 1;
                transform: translateX(0px);
                margin-right: 20px;
                .menu-item {
                    cursor: pointer;
                    padding: 0 10px;
                }
            }
            .icon {
                position: relative;
                width: 16px;
                height: 16px;
                margin-right: 10px;
                cursor: pointer;

                .open {
                    position: absolute;
                    opacity: 0;
                    pointer-events: none;
                }
                .close {
                    position: absolute;
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            &.closed {
                .actual-items {
                    opacity: 0;
                    transform: translateX(30px);
                    pointer-events: none;
                }
                .icon {
                    .open {
                        opacity: 1;
                        pointer-events: auto;
                    }
                    .close {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }

        .button {
            background: #b7ff6e;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            font-weight: bold;
            font-size: 14px;
            color: #201f1d;
            cursor: no-drop;
            padding: 10px 35px;
            transition: all 0.4s ease;

            &.enabled {
                cursor: pointer;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

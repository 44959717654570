@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    color: #254cdd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Consolas';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/Consolas.ttf');
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

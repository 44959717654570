.App {
    width: 100vw;
    height: 100vh;
    background: #161518;
    overflow: hidden;
    position: relative;

    &.fadedLeft {
        &:before {
            opacity: 0.75;
        }
    }

    &.fadedRight {
        &:after {
            opacity: 0.75;
        }
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 30%;
        height: 100%;
        top: 0;
        z-index: 2;
        background: linear-gradient(90deg, #161518 0%, rgba(22, 21, 24, 0) 100%);
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease;
    }

    &:before {
        left: 0;
    }

    &:after {
        right: 0;
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}
.scroll-container {
    height: calc(100% - 126px);
    width: 100%;
    overflow: auto;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}
.tweet {
    background: linear-gradient(261.35deg, #343029 0%, #242424 96.26%);
    border-radius: 4px;
    color: white;
    margin-bottom: 20px;
    width: 450px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    user-select: none;
    position: relative;
    padding: 20px 20px 60px;
    transition: all 0.4s ease;

    &:hover {
        transform: translateY(-10px);

        img {
            transform: scale(1.1);
        }
        .details {
            transform: translateX(3px);
        }

        .first {
            opacity: 0 !important;
            pointer-events: none !important;
            transform: translateX(10px) !important;
        }

        .hidden {
            opacity: 1 !important;
            pointer-events: auto !important;
            transform: translateX(0px) !important;
        }
    }

    &.sold {
        background: linear-gradient(261.35deg, #34292a 0%, #242424 96.26%);
    }

    .overlay {
        background: linear-gradient(261.35deg, #343029 0%, #242424 96.26%);
        opacity: 0;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        pointer-events: none;
        transition: all 0.5s ease;

        &.show {
            pointer-events: auto;
            opacity: 0.96;

            .info-text {
                font-family: Consolas, sans-serif;
                font-size: 14px;
                color: #b7ff6e;
                margin-top: 10px;

                a {
                    color: #b7ff6e !important;
                }
            }

            .lds-default {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
            }
            .lds-default div {
                position: absolute;
                width: 6px;
                height: 6px;
                background: #b7ff6e;
                border-radius: 50%;
                animation: lds-default 1.2s linear infinite;
            }
            .lds-default div:nth-child(1) {
                animation-delay: 0s;
                top: 37px;
                left: 66px;
            }
            .lds-default div:nth-child(2) {
                animation-delay: -0.1s;
                top: 22px;
                left: 62px;
            }
            .lds-default div:nth-child(3) {
                animation-delay: -0.2s;
                top: 11px;
                left: 52px;
            }
            .lds-default div:nth-child(4) {
                animation-delay: -0.3s;
                top: 7px;
                left: 37px;
            }
            .lds-default div:nth-child(5) {
                animation-delay: -0.4s;
                top: 11px;
                left: 22px;
            }
            .lds-default div:nth-child(6) {
                animation-delay: -0.5s;
                top: 22px;
                left: 11px;
            }
            .lds-default div:nth-child(7) {
                animation-delay: -0.6s;
                top: 37px;
                left: 7px;
            }
            .lds-default div:nth-child(8) {
                animation-delay: -0.7s;
                top: 52px;
                left: 11px;
            }
            .lds-default div:nth-child(9) {
                animation-delay: -0.8s;
                top: 62px;
                left: 22px;
            }
            .lds-default div:nth-child(10) {
                animation-delay: -0.9s;
                top: 66px;
                left: 37px;
            }
            .lds-default div:nth-child(11) {
                animation-delay: -1s;
                top: 62px;
                left: 52px;
            }
            .lds-default div:nth-child(12) {
                animation-delay: -1.1s;
                top: 52px;
                left: 62px;
            }
            @keyframes lds-default {
                0%,
                20%,
                80%,
                100% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.5);
                }
            }
        }
    }

    .top {
        display: flex;

        .left {
            .user {
                display: flex;

                img {
                    border-radius: 50%;
                    width: 40px;
                    transition: all 0.6s ease;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 7px;
                    transition: all 0.6s ease;

                    .name {
                        font-weight: 500;
                        font-size: 14px;
                        margin-bottom: 2px;
                    }
                    .at {
                        font-weight: 500;
                        font-size: 12px;
                        color: #8f8f8f;
                    }
                }
            }
        }

        .right {
            margin-left: auto;

            .sold {
                font-family: Roboto, serif;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                color: #c55758;
            }

            .price {
                background: rgba(#000000, 0.5);
                border-radius: 4px;
                display: flex;
                align-items: center;
                position: relative;
                height: 35px;

                .price-in-usd {
                    font-family: Consolas, sans-serif;
                    color: #8f8f8f;
                    font-size: 12px;
                    margin-right: 65px;
                    margin-left: 15px;
                }

                .price-in-eth {
                    font-family: Consolas, sans-serif;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #ffc06e;
                    border-radius: 4px;
                    height: 100%;
                    font-size: 12px;
                    font-weight: bold;
                    position: absolute;
                    right: 0;
                    color: #714922;
                }
            }
        }
    }

    .body {
        margin-top: 20px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 20px;
        color: #ffffff;
    }

    .bottom {
        position: absolute;
        background: #201f1d;
        bottom: 0;
        width: calc(100% - 40px);
        left: 0;
        border-radius: 0 0 4px 4px;
        display: flex;
        height: 60px;
        padding: 0 20px;
        align-items: center;

        .left {
            font-family: Consolas, serif;
            font-size: 14px;
            color: #ffc06e;
            position: relative;

            .first {
                opacity: 1;
                pointer-events: auto;
                transform: translateX(0px);
                transition: all 0.5s ease;
            }

            .hidden {
                opacity: 0;
                pointer-events: none;
                transform: translateX(-15px);
                transition: all 0.5s ease;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .right {
            margin-left: auto;

            .button {
                background: #b7ff6e;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                font-weight: bold;
                font-size: 12px;
                color: #201f1d;
                cursor: pointer;
                padding: 8px 15px;
                transition: all 0.4s ease;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}

.TweetGrid {
    display: inline-flex;
    flex-direction: column;
    margin-left: 20px;

    &:last-child {
        margin-right: 20px;
    }
}

.breaker {
    flex-basis: 100%;
}
